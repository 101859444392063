import React from 'react'
import Image from 'gatsby-image'
import {graphql, StaticQuery} from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'

import BlockContent from '../components/portableText'

import styles from '../components/page.module.css'

export default class Index extends React.Component {
  render () {
    return (

      <StaticQuery
        query={graphql`
            query sanityDonatePage {
              sanityDonate {
                title
                _rawBody
                mainImage {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                seoSettings {
                  title
                  description
                }
              }
            }
          `}
        render={data => (
          <>
            {data.sanityDonate.seoSettings && data.sanityDonate.seoSettings.title && data.sanityDonate.seoSettings.description && (<SEO title={data.sanityDonate.seoSettings.title} description={data.sanityDonate.seoSettings.description} />)}

            <Layout Layout mainImage={data.sanityDonate.mainImage}>

              <article className={styles.root} >

                <Container>
                  <div className={styles.pageTitleWrapper}>
                    <h1 className={styles.pageTitle}>{data.sanityDonate.title}</h1>
                  </div>
                  <div>
                    <div className={styles.mainContent}>
                      <div style={{paddingRight: '30px', width: '100%'}}>
                        {data.sanityDonate._rawBody && <BlockContent blocks={data.sanityDonate._rawBody} />}
                      </div>

                    </div>
                  </div>
                </Container>

              </article>

            </Layout>
          </>
        )}
      />
    )
  }
}
